import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, IconSpinner, Modal, PrimaryButton } from '../../../components';
import { PayoutClaimType } from '../StoreCredits/StoreCredits';
import csCss from './CreditSelection.module.css';
import css from './PayoutModal.module.css';
import { useGetBrandIdOptions } from 'hooks/api/listings/useGetBrandIdOptions';
import { useClaimPayout } from 'hooks/api/payouts';
import { useGetConfiguration } from 'hooks/api/useGetConfiguration';
import { addSymbolToFormattedPrice, formatMoney } from 'util/currency';

export const PayoutModal = ({ payoutModalOpen, setPayoutModalOpen, transaction }) => {
  const { mutateAsync: claimPayout, isLoading } = useClaimPayout();
  const [claimType, setClaimType] = useState<PayoutClaimType | null>(null);
  const intl = useIntl();

  const [creditSelection, setCreditSelection] = useState(false);
  useEffect(() => {
    if (!payoutModalOpen) {
      setClaimType(null);
      setCreditSelection(false);
    }
  }, [payoutModalOpen]);

  const itemPrice = {
    amount: transaction.payout.baseAmount * 100,
    currency: transaction.payout.currency,
  };

  const price = itemPrice ? formatMoney(intl, itemPrice) : null;

  const creditPayout = addSymbolToFormattedPrice(
    intl,
    transaction.payout.creditAmount,
    transaction.attributes.payoutTotal.currency
  );

  const cashPayout = addSymbolToFormattedPrice(
    intl,
    transaction.payout.cashAmount,
    transaction.attributes.payoutTotal.currency
  );
  const { data: config } = useGetConfiguration();
  const partner = config?.partners?.find(partner => partner.id === transaction.payout.partnerId);
  const partnerName = partner?.name;

  return (
    <Modal
      open={payoutModalOpen}
      onOpenChange={setPayoutModalOpen}
      title={
        creditSelection
          ? 'Claim store credit - must be used within 90 days'
          : intl.formatMessage({ id: 'ProfilePage.wallet.payoutModal.title' })
      }
    >
      {creditSelection ? (
        <CreditSelection transaction={transaction} setPayoutModalOpen={setPayoutModalOpen} />
      ) : (
        <div className={css.root}>
          <div className={css.listing}>
            <div className={css.listing__data}>
              <span>{transaction.listing.title}</span>
              <span>{price}</span>
            </div>
          </div>
          <div className={css.buttonsContainer}>
            <div
              className={css.button}
              data-active={claimType === PayoutClaimType.Credit}
              onClick={() => setClaimType(PayoutClaimType.Credit)}
            >
              <span>{creditPayout}</span>

              <FormattedMessage
                id="ProfilePage.wallet.payoutModal.storeCreditDetails"
                values={{
                  percentage: Math.round(
                    (transaction.payout.creditAmount / transaction.payout.baseAmount) * 100
                  ),
                  brand: partnerName ?? '100+ brands',
                }}
              />
            </div>
            <span style={{ margin: '0 auto' }}>Or</span>
            <div
              className={css.button}
              data-active={claimType === PayoutClaimType.Cash}
              onClick={() => setClaimType(PayoutClaimType.Cash)}
            >
              <span>{cashPayout}</span>
              <FormattedMessage
                id="ProfilePage.wallet.payoutModal.cashDetails"
                values={{
                  percentage: Math.round(
                    (transaction.payout.cashAmount / transaction.payout.baseAmount) * 100
                  ),
                }}
              />
            </div>
          </div>
          <p className={css.disclaimer}>
            <FormattedMessage id="ProfilePage.wallet.payoutModal.disclaimer" />
          </p>
          <Modal.Footer>
            <div className={css.actionButtons}>
              <Button
                onClick={() => {
                  setClaimType(null);
                  setPayoutModalOpen(false);
                }}
              >
                <FormattedMessage id="ProfilePage.wallet.payoutModal.cancel" />
              </Button>
              <PrimaryButton
                onClick={async () => {
                  if (claimType === PayoutClaimType.Credit && !transaction.payout.partnerId) {
                    setCreditSelection(true);
                    return;
                  }

                  if (claimType) {
                    try {
                      await claimPayout({ payoutId: transaction.payout.id, claimType });
                    } catch (error: any) {
                      toast.error(
                        `${intl.formatMessage({
                          id: 'ProfilePage.wallet.payoutModal.errorClaiming',
                        })}
                      ${error.data?.errors[0].displayMessage}`,
                        { duration: 10000 }
                      );
                    }
                    setPayoutModalOpen(false);
                  }
                }}
                inProgress={isLoading}
                disabled={isLoading}
              >
                <FormattedMessage id="ProfilePage.wallet.payoutModal.claim" />
              </PrimaryButton>
            </div>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

enum RedemptionType {
  Online = 1,
  Physical = 2,
  OnlineAndPhysical = 3,
}

export function getRedemptionTypeString(type: RedemptionType) {
  switch (type) {
    case RedemptionType.Online:
      return 'online';
    case RedemptionType.Physical:
      return 'in-store';
    case RedemptionType.OnlineAndPhysical:
      return 'online | in-store';
  }
}

const CreditSelection = ({ transaction, setPayoutModalOpen }) => {
  const { mutateAsync: claimPayout, isLoading } = useClaimPayout();
  const [selected, setSelected] = useState();

  const { data: config, isLoading: isGettingPartners } = useGetBrandIdOptions();
  const defaultPartnerId = config?.defaultPartnerId;
  const partners = config?.partners || [];

  useEffect(() => {
    if (defaultPartnerId && !selected) {
      setSelected(defaultPartnerId);
    }
  }, [defaultPartnerId, selected]);

  const creditAmount = Number(transaction.payout.creditAmount);
  const cards = [
    {
      id: defaultPartnerId,
      icon: 'THE NOLD',
      title: 'The Nold',
      description: '100% payout. 0% commission',
      use: 'Use: online at thenold.com',
      logoUrl: 'https://cdn.thenold.com/logo.png',
      logoStyles: { backgroundSize: '75%' },
    },
    ...partners
      .map(partner => ({
        ...partner,
        icon: <img src={partner.logoUrl} alt={partner.name} />,
        title: partner.name,
        description: `${partner.creditPayoutPercentageUser}% payout. ${partner.creditPayoutPercentage}% commission`,
        use: `Use: ${getRedemptionTypeString(partner.redemptionType)}`,
        tncUrl: partner.termsAndConditions,
      }))
      .filter(
        partner =>
          partner.lowerLimit <= creditAmount &&
          partner.upperLimit >= creditAmount &&
          partner.storeCreditCurrency.toLowerCase() === transaction.payout.currency.toLowerCase()
      ),
  ];

  return (
    <div className={csCss.root}>
      {cards.map(card => {
        return (
          <div
            className={csCss.item}
            data-selected={card.id === selected}
            onClick={() => setSelected(card.id)}
            key={card.id}
          >
            <div className={csCss.cardRow}>
              <div
                className={csCss.card}
                style={
                  card.logoUrl && {
                    backgroundImage: `url(${card.logoUrl})`,
                    ...card.logoStyles,
                  }
                }
              />
              <div className={csCss.mainInfo}>
                <h1>{card.title}</h1>
                <h2>{card.description}</h2>
              </div>
            </div>
            <div className={csCss.useInfo}>
              <span>{card.use}</span>
              {card.tncUrl && (
                <a
                  href={card.tncUrl}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  Terms & conditions
                </a>
              )}
            </div>
          </div>
        );
      })}
      {isGettingPartners && (
        <div style={{ display: 'grid', justifyContent: 'center', padding: 'var(--n-size-4)' }}>
          <IconSpinner />
        </div>
      )}
      <Modal.Footer>
        <div className={css.actionButtons} style={{ position: 'sticky' }}>
          <Button
            onClick={() => {
              setPayoutModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <PrimaryButton
            disabled={!selected}
            inProgress={isLoading}
            onClick={async () => {
              await claimPayout({
                payoutId: transaction.payout.id,
                claimType: PayoutClaimType.Credit,
                partnerId: cards.find(card => card.id === selected).id,
              });
              setPayoutModalOpen(false);
            }}
          >
            Confirm
          </PrimaryButton>
        </div>
      </Modal.Footer>
    </div>
  );
};
