import { cn } from 'util/cn';

const LoaderIndeterminate = ({
  isLoading,
  className,
}: {
  isLoading: boolean;
  className?: string;
}) => {
  return (
    <div
      data-loading={isLoading}
      className={cn(
        'data-[loading=true]:block hidden absolute w-full h-1 bg-gray-200 overflow-hidden -top-1 animate-in fade-in duration-1000',
        className
      )}
    >
      <div className="absolute w-1/2 h-full bg-black animate-indeterminate-loading"></div>
    </div>
  );
};

export default LoaderIndeterminate;
