import { useMutation } from '@tanstack/react-query';

import { useSendEvent } from '../misc/useSendEvent';
import { Listing } from 'models/ListingModels';
import { createListingSchema } from 'models/ListingSchemas';
import { mapListingResponseToListing } from 'models/mappers/ListingMapper';
import { AnalyticsEvent } from 'util/analytics-events';
import { post } from 'util/httpsClient';
import { omitUndefined } from 'util/httpUtils';
import { useSdk } from 'util/sdkContext';

export const useCreateListing = () => {
  const { mutate: sendEvent } = useSendEvent();
  const sdk = useSdk();

  return useMutation({
    mutationKey: ['createListing'],
    mutationFn: async (listing: Partial<Listing>) => {
      try {
        const createListingRequest = omitUndefined({
          publicData: {
            brandId: listing.brandId?.toString(),
            originalPhotoIds: listing.originalPhotoIds || [],
            removedBgPhotoIds: listing.removedBgPhotoIds || [],
            photoIds: listing.images || [],
            description: listing.description || '',
            partnerId: listing.partnerId || '',
            priceWhenBought: listing.priceWhenBought || '',
            priceWhenBoughtCurrency: listing.priceWhenBoughtCurrency || '',
            department: listing.department || '',
            category: listing.category || '',
            productType: listing.productType || '',
            productSubtype: listing.productSubtype || '',
            colors: listing.colors || [],
          },
          title: listing.title || ' ',
        });

        const sharetribeListingResponse = await createSharetribeListing(sdk, createListingRequest);

        const sharetribeListing = sharetribeListingResponse?.data?.data;

        const listingId = sharetribeListing?.id?.uuid;
        if (listingId) {
          sendEvent({
            event: AnalyticsEvent.CreateListing,
            params: { listingId },
          });
        }

        listing.id = listingId;
        const listingResponse = await createListingDomain(listing);

        return mapListingResponseToListing(listingResponse);
      } catch (error) {
        console.error('Error creating listing:', error);
        throw error;
      }
    },
    meta: { name: 'createListing' },
  });
};

async function createSharetribeListing(sdk: any, data: any) {
  const searchWords = [
    data.title,
    data.publicData?.brandName,
    data.publicData?.productType,
    data.publicData?.productSubtype,
    data.publicData?.category,
    data.publicData?.colors?.join(' '),
  ]
    .filter(Boolean)
    .join(' ');

  if (searchWords) {
    data.description = searchWords;
  }

  const queryParams = {
    expand: true,
    include: ['author', 'currentStock'],
  };

  return await sdk.ownListings.createDraft(data, queryParams);
}

function createListingDomain(listingData: any): any {
  return post({ path: '/listings', body: createListingSchema.parse(listingData) });
}
