import classNames from 'classnames';
import React from 'react';

import css from './Stepper.module.css';
import { useMediaQueries } from 'hooks/useMediaQueries';

type Props = {
  children: React.ReactNode;
  className?: string;
  rootClassName?: string;
};

const Stepper: React.FC<Props> = props => {
  const { children, className, rootClassName } = props;
  const classes = classNames(rootClassName, css.root, className);

  const tabNavTabs = React.Children.map(children, (child: any) => {
    const { tabId, tabLabel } = child.props;

    // Child components need to have TabNav props included
    if (!tabId || !tabLabel) {
      throw new Error(
        `Tabs component: a child component is missing required props.
        tabId: (${tabId})
        tabLabel: (${tabLabel})`
      );
    }

    return {
      id: tabId,
      text: child.props.tabLabel,
      disabled: child.props.disabled,
      selected: child.props.selected,
    };
  });

  const childArray = React.Children.toArray(children);
  // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'ReactChil... Remove this comment to see the full error message
  const selectedTabPanel = childArray.find(c => c.props.selected);

  // One of the children needs to be selected
  if (!selectedTabPanel) {
    throw new Error(`Tabs component: one Child should have 'selected' prop.`);
  }

  const isLastSelected = tabNavTabs?.[tabNavTabs.length - 1]?.selected;
  const isDesktop = useMediaQueries({ viewport: 'small' });
  const currentTab = tabNavTabs?.find(x => x.selected);

  if (!currentTab) {
    throw new Error(`Tabs component: one Child should have 'selected' prop.`);
  }

  return (
    <div className={classes}>
      {!isDesktop && !isLastSelected && (
        <div className={css.stepperMobileTitle}>{currentTab.text}</div>
      )}
      {!isLastSelected && (
        <div className={css.stepperItems}>
          {tabNavTabs?.slice(0, -1).map((tabData, index) => {
            // Check if we are on the next step, to mark latest as completed.
            const isStepCompleted =
              !tabData.selected && tabNavTabs.findIndex(x => x.selected) > index;

            return (
              <div
                key={tabData.id}
                className={classNames(css.stepperItem, {
                  [css.active]: tabData.selected,
                  [css.completed]: isStepCompleted,
                })}
              >
                <div className={css.stepCounter}>{index + 1}</div>
                {isDesktop && <div className={css.stepName}>{tabData.text}</div>}
              </div>
            );
          })}
        </div>
      )}
      {selectedTabPanel}
    </div>
  );
};

export default Stepper;
