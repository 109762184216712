// TODO: FIXME: This logic is duplicated with src/util/images.ts

export const ImageSize = {
  ORIGINAL: 'original',
  BLUR: 'blur',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
};

export const sizeToWidth = {
  [ImageSize.BLUR]: 33,
  [ImageSize.SMALL]: 400,
  [ImageSize.MEDIUM]: 800,
  [ImageSize.LARGE]: 1200,
  [ImageSize.XLARGE]: 1600,
};
