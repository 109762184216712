import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { put } from '../../../util/httpsClient';
import { fetchCurrentUser } from 'ducks/user.duck';

export function useUpdateStripeAccount() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (data?: any) => {
      return put({ path: '/users/stripe', body: data });
    },
    onSuccess: () => {
      dispatch(fetchCurrentUser());
      queryClient.invalidateQueries(['stripeAccount']);
    },
    meta: {
      name: 'updateStripeAccount',
    },
  });
}
