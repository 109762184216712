import { useNode } from '@craftjs/core';
import React from 'react';

export const HeroSectionSettings = () => {
  const {
    actions: { setProp },
    imgSrc,
    imgBlurSrc,
    imgMobileSrc,
    imgMobileBlurSrc,
    imgAlt,
    objectPosition,
    mobileObjectPosition,
  } = useNode(node => ({
    imgSrc: node.data.props.imgSrc,
    imgBlurSrc: node.data.props.imgBlurSrc,
    imgMobileSrc: node.data.props.imgMobileSrc,
    imgMobileBlurSrc: node.data.props.imgMobileBlurSrc,
    imgAlt: node.data.props.imgAlt,
    objectPosition: node.data.props.objectPosition,
    mobileObjectPosition: node.data.props.mobileObjectPosition,
  }));

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-lg font-semibold">Hero Section Settings</h3>

      <div className="flex flex-col gap-2">
        <label className="flex flex-col">
          Image Source
          <input
            type="text"
            value={imgSrc}
            onChange={e => setProp((props: any) => (props.imgSrc = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>

        <label className="flex flex-col">
          Blur Image Source
          <input
            type="text"
            value={imgBlurSrc}
            onChange={e => setProp((props: any) => (props.imgBlurSrc = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>

        <label className="flex flex-col">
          Mobile Image Source (optional)
          <input
            type="text"
            value={imgMobileSrc || ''}
            onChange={e => setProp((props: any) => (props.imgMobileSrc = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>

        <label className="flex flex-col">
          Mobile Blur Image Source (optional)
          <input
            type="text"
            value={imgMobileBlurSrc || ''}
            onChange={e => setProp((props: any) => (props.imgMobileBlurSrc = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>

        <label className="flex flex-col">
          Image Alt Text
          <input
            type="text"
            value={imgAlt}
            onChange={e => setProp((props: any) => (props.imgAlt = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>

        <a
          href="https://css-tricks.com/almanac/properties/o/object-position/"
          target="_blank"
          rel="noreferrer"
        >
          How to use Object Position
        </a>
        <label className="flex flex-col">
          Desktop Image Position
          <input
            type="text"
            value={objectPosition}
            placeholder="50% 100% (horizontal, vertical)"
            onChange={e => setProp((props: any) => (props.objectPosition = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>

        <label className="flex flex-col">
          Mobile Image Position
          <input
            type="text"
            value={mobileObjectPosition}
            placeholder="50% 100% (horizontal, vertical)"
            onChange={e => setProp((props: any) => (props.mobileObjectPosition = e.target.value))}
            className="border rounded px-2 py-1"
          />
        </label>
      </div>
    </div>
  );
};
