import { useEffect } from 'react';

import { LogoNold } from 'assets/icons';
import { Button, NamedLink, Page } from 'components';
import { logger } from 'util/log';

export const ErrorPage = ({ error }: { error: Error }) => {
  useEffect(() => {
    logger.exception(error);
  }, [error]);

  return (
    <Page title={'Whoops! Something went wrong'} scrollingDisabled={false}>
      <div className="mx-auto container p-3 text-center grid place-content-center place-items-center gap-4 h-screen">
        <NamedLink name="LandingPage">
          <LogoNold width={170} />
        </NamedLink>
        <h1 className="text-2 font-bold">
          Whoops! Something went wrong <span className="text-nowrap">:(</span>
        </h1>
        <p className="description">We're working hard to fix the issue. Please try again later.</p>
        {process.env.NODE_ENV === 'development' && (
          <div className="text-red-500 grid gap-2 text-left">
            <div className="text-center">
              <strong>Development mode. Check console.</strong>
              <br />
              {error?.toString()}
            </div>
            <pre className="max-h-[400px] overflow-y-auto p-2 border border-red-500 rounded-md">
              <strong>JSON:</strong> {JSON.stringify(error, null, 2)}
            </pre>
            {'stack' in error && (
              <pre className="max-h-[400px] overflow-y-auto p-2 border border-red-500 rounded-md">
                <strong>Stack:</strong> {error?.stack}
              </pre>
            )}
          </div>
        )}
        <NamedLink name="LandingPage">
          <Button>Go to Home</Button>
        </NamedLink>
      </div>
    </Page>
  );
};
