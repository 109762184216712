import { CardElement, Elements } from '@stripe/react-stripe-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { toast } from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import * as log from '../../util/log';
import { CheckoutAddressFieldsGuest } from './CheckoutAddressFields/CheckoutAddressFieldsGuest';
import { CheckoutCheckbox } from './CheckoutCheckbox/CheckoutCheckbox';
import { CheckoutFormData, OrderData } from './CheckoutPage';
import {
  useCreatePaymentMethod,
  useCreateStripeAccountIfNotExistent,
  useShouldRedirect,
  useSubmitPaymentRequest,
} from './CheckoutPage.hooks';
import css from './CheckoutPage.module.css';
import { CheckoutShippingMethodFields } from './CheckoutShippingMethodFields/CheckoutShippingMethodFields';
import CheckoutSummary from './CheckoutSummary/CheckoutSummary';
import GuestEmailExistsModal from './GuestEmailExistsModal';
import { IconChevronLeft, LogoNold } from 'assets/icons';
import { IconSpinner, NamedLink, NamedRedirect, Page, PrimaryButton } from 'components';
import { ConditionType } from 'config/configListing';
import { stripePromise } from 'config/configStripe';
import { LegalPageTab } from 'containers/LegalPage/LegalPage';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import { useGetListing } from 'hooks/api/listings/useGetListing';
import { useSendEvent } from 'hooks/api/misc/useSendEvent';
import { useGetLineItems } from 'hooks/api/transactions/useGetLineItems';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { useGeolocation } from 'hooks/useGeolocation';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { isEUCountry } from 'translations/countryCodes';
import { AnalyticsEvent } from 'util/analytics-events';
import { pathByRouteName } from 'util/routes';
import { useScrollToFirstError } from 'util/scrollUtils';
import { isGuestUser } from 'util/user';

export const CHECKOUT_FORM_ID = 'checkout-form';

export type DiscountCodeState = {
  input: string;
  error: string;
  isError: boolean;
  isApplied: boolean;
};

const CheckoutPageGuest = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutPageGuestHelper />
    </Elements>
  );
};

const CheckoutPageGuestHelper = () => {
  const params = useParams<{ id: string }>();
  const listingId = params.id;
  const scrollingDisabled = useIsScrollingDisabled();
  const intl = useIntl();
  const { userGeolocation } = useGeolocation();
  const { data: listing, isLoading: isListingLoading } = useGetListing(listingId);
  const { currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
  const [isRatesLoading, setIsRatesLoading] = useState(false);
  const [userExistsModalOpen, setUserExistsModalOpen] = useState(false);
  const { mutate: sendEvent } = useSendEvent();

  const [shippingRates, setShippingRates] = useState<any>(null);

  const isGuest = useMemo(() => {
    return isGuestUser(currentUser);
  }, [currentUser]);

  const [discountCodeState, setDiscountCodeState] = useState<DiscountCodeState>({
    input: '',
    error: '',
    isError: false,
    isApplied: false,
  });

  const [orderData, setOrderData] = useState<OrderData>({
    shippingAddressId: '',
    billingAddressId: '',
    selectedRateId: '',
    discountCodes: [],
    useNoldCredit: false,
    noldCreditAmount: 0,
    deliveryMethod: '',
    authenticate: false,
    shippingToCountry: '',
  });

  const handleSetRates = useCallback(
    (rates: any, country: any) => {
      setShippingRates(rates);
      setOrderData(prev => ({
        ...prev,
        selectedRateId: rates[0]?.id || '',
        shippingToCountry: country,
      }));
    },
    [setShippingRates, setOrderData]
  );

  useEffect(() => {
    sendEvent({
      event: AnalyticsEvent.VisitCheckout,
      params: { isGuest: true, listingId },
    });
  }, [sendEvent, listingId]);

  const [useDeliveryAddressForBilling, setUseDeliveryAddressForBilling] = useState(true);

  const handleApplyDiscountCode = async code => {
    if (!orderData.discountCodes) {
      setOrderData(prev => ({ ...prev, discountCodes: [] }));
    }

    if (orderData.discountCodes && orderData.discountCodes.includes(code)) {
      setDiscountCodeState(prev => ({
        ...prev,
        error: 'This discount code has already been applied',
      }));
      return;
    }

    setDiscountCodeState(prev => ({
      ...prev,
      error: '',
      isError: false,
      isApplied: true,
    }));

    setOrderData(prev => ({ ...prev, discountCodes: [...prev.discountCodes, code] }));
  };

  const handleRemoveDiscountCode = code => {
    setDiscountCodeState({
      error: '',
      isError: false,
      isApplied: false,
      input: '',
    });

    setOrderData(prev => ({
      ...prev,
      discountCodes: prev.discountCodes ? prev.discountCodes.filter(c => c !== code) : [],
    }));
  };

  const { error } = useCreatePaymentMethod();

  const isDesktop = useMediaQueries({ viewport: 'large' });

  const {
    data: lineItems,
    error: lineItemsError,
    isLoading: isLoadingLineItems,
  } = useGetLineItems({
    listingId,
    rateId: orderData.selectedRateId,
    userGeolocation: userGeolocation,
    discountCodes: orderData.discountCodes,
    shippingToCountry: orderData.shippingAddress?.country || orderData.shippingToCountry,
  });

  const amountToPay = lineItems?.payinTotal || 0;
  const { mutateAsync: handleSubmit, isLoading: isPaying } = useSubmitPaymentRequest({
    amountToPay,
    listingId,
    orderData,
    isGuest,
  });
  const [cardInteractionEventSent, setCardInteractionEventSent] = useState(false);
  const [cardElementFilled, setCardElementFilled] = useState(false);
  const [cardElementEmptyAfterSubmit, setCardElementEmptyAfterSubmit] = useState(false);

  const shouldRedirect = useShouldRedirect();
  const { isCreatingStripeAccount, stripeAccountCreateError } = useCreateStripeAccountIfNotExistent(
    shouldRedirect,
    isGuest
  );

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const scrollToFirstError = useScrollToFirstError({
    errorClassName: 'input-error',
    cardElementClassName: css.cardElement,
    onCardElementEmpty: () => setCardElementEmptyAfterSubmit(true),
  });

  if (!listing || !listing.shippingAddress) {
    return null;
  }

  const isListingFromEu = !isListingLoading && isEUCountry(listing?.shippingAddress?.country);
  const showAddressesFrom =
    !isListingLoading && listing?.condition === ConditionType.SampleSale
      ? 'all'
      : isListingFromEu
      ? 'EU'
      : 'GB';

  if (lineItemsError) {
    const error = lineItemsError.data?.errors?.[0];
    if (error?.type === 'DiscountCodeError') {
      console.error('Failed to apply discount code:', lineItemsError);
      const errorMessage =
        error?.displayMessage || 'Failed to apply discount code. Please try again.';

      setDiscountCodeState(prev => ({
        ...prev,
        error: errorMessage,
        isError: true,
        isApplied: false,
      }));

      setOrderData(prev => ({
        ...prev,
        discountCodes: prev.discountCodes.slice(0, -1),
      }));
    } else if (error?.type === 'OutOfStockError') {
      console.error('Out of stock error:', lineItemsError);
      toast.error('Sorry, this item is no longer available', {
        duration: 10000,
      });

      const searchPagePath = pathByRouteName('SearchPage', routeConfiguration);
      history.push(`${searchPagePath}`);
    } else if (error?.type === 'ListingIsNotForSaleInEU') {
      console.error('Listing not for sale in the EU:', lineItemsError);
      log.error(error, 'listing-not-for-sale-in-eu', lineItemsError);
      toast.error(lineItemsError.data?.errors[0]?.displayMessage, {
        duration: 10000,
      });

      const searchPagePath = pathByRouteName('SearchPage', routeConfiguration);
      history.push(`${searchPagePath}`);
    }
  }

  if (isLoadingCurrentUser) {
    return (
      <div className={css.formLoadOverlay}>
        <IconSpinner />
      </div>
    );
  }

  // Redirect back to ListingPage if data is missing.
  // Redirection must happen before any data format error is thrown (e.g. wrong currency)
  if (shouldRedirect || stripeAccountCreateError) {
    // eslint-disable-next-line no-console
    console.error('Missing or invalid data for guest checkout, redirecting back to listing page.', {
      listingId,
    });
    if (stripeAccountCreateError) {
      toast.error('Failed to set up your stripe account. Please contact our support team.');
      log.error(stripeAccountCreateError, (stripeAccountCreateError as any).code, undefined);
    }
    return <NamedRedirect name="ListingPage" params={params} />;
  }

  return (
    <>
      <FinalForm<CheckoutFormData>
        destroyOnUnregister
        onSubmit={async values => {
          if (!cardElementFilled) {
            toast.error('Please complete the card details before submitting.');
            return;
          }

          try {
            await handleSubmit(values);
            toast.success(intl.formatMessage({ id: 'CheckoutPage.paymentSuccess' }));
          } catch (e: any) {
            console.error('Error submitting payment:', e);
            if (e.status === 409) {
              setUserExistsModalOpen(true);
            }
          }
        }}
        validate={values => {
          const errors: Record<string, string> = {};

          if (!cardElementFilled) {
            errors.cardElement = 'Please fill in your card details';
          }

          const addressFields = [
            'email',
            'fullName',
            'phoneNumber',
            'street1',
            'city',
            'zip',
            'country',
          ];
          const isAddressComplete = addressFields.every(field => !!values[field]);

          if (!isAddressComplete) {
            errors.shippingAddress = 'Please provide a complete shipping address';
          }

          if (!useDeliveryAddressForBilling) {
            const billingFields = [
              'billingFullName',
              'billingPhoneNumber',
              'billingStreet1',
              'billingCity',
              'billingZip',
              'billingCountry',
            ];
            const isBillingComplete = billingFields.every(field => values[field]);

            if (!isBillingComplete) {
              errors.billingAddress = 'Please provide a complete billing address';
            }
          }

          return Object.keys(errors).length > 0 ? errors : undefined;
        }}
        render={({ handleSubmit, values, form }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitAttempted) {
              const errors = form.getState().errors;
              if (errors && Object.keys(errors).length > 0) {
                scrollToFirstError(cardElementFilled);
              }
              setSubmitAttempted(false);
            }
          }, [form]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setOrderData(v => ({
              ...v,
              selectedRateId: values.shippingRateId,
            }));
          }, [values.shippingRateId]);

          return (
            <Page
              title={intl.formatMessage({ id: 'CheckoutPage.title' })}
              scrollingDisabled={scrollingDisabled}
              className={css.root}
            >
              <div className={css.root}>
                <div className={css.header}>
                  <div className={css.maxWidthContainer}>
                    <NamedLink name="LandingPage" className="mx-auto">
                      <LogoNold />
                    </NamedLink>
                    {!isDesktop && (
                      <div
                        className="absolute font-syne uppercase z-10 inline-flex items-center text-000 font-bold py-.5 pr-2 pl-1"
                        onClick={() => history.goBack()}
                      >
                        <IconChevronLeft className="!size-[28px]" />
                      </div>
                    )}
                  </div>
                </div>
                <div className={css.mainContainer}>
                  <div className={css.main + ' ' + css.maxWidthContainer}>
                    <form
                      id={CHECKOUT_FORM_ID}
                      onSubmit={event => {
                        event.preventDefault();
                        setSubmitAttempted(true);
                        handleSubmit();
                      }}
                      className={css.main__inputs}
                    >
                      {isCreatingStripeAccount && (
                        <div className={css.formLoadOverlay}>
                          <IconSpinner />
                        </div>
                      )}
                      <div className={css.section}>
                        <div className={css.section__title}>Deliver to</div>
                        <CheckoutAddressFieldsGuest
                          showAddressesFrom={showAddressesFrom}
                          setRates={handleSetRates}
                          setIsRatesLoading={setIsRatesLoading}
                          isLoadingCurrentUser={isLoadingCurrentUser}
                        />
                        <CheckoutCheckbox
                          labelClassName={css.sameAsDeliveryCheckbox}
                          checked={useDeliveryAddressForBilling}
                          onCheckedChange={setUseDeliveryAddressForBilling}
                          label="Use delivery address as billing address"
                        />
                      </div>
                      {!useDeliveryAddressForBilling ? (
                        <div className={css.section}>
                          <div className={css.section__title}>Billing address</div>
                          <CheckoutAddressFieldsGuest
                            showAddressesFrom="all"
                            billing={true}
                            setRates={handleSetRates}
                            setIsRatesLoading={setIsRatesLoading}
                            isLoadingCurrentUser={isLoadingCurrentUser}
                          />
                        </div>
                      ) : null}

                      <div className={css.section}>
                        <div className={css.section__title}>Choose a provider</div>
                        <CheckoutShippingMethodFields
                          rates={shippingRates}
                          isRatesLoading={isRatesLoading}
                        />
                      </div>
                      <div className={css.section}>
                        <div className={css.section__title}>Payment</div>
                        <CardElement
                          className={css.cardElement}
                          options={{
                            style: {
                              base: {
                                fontFamily: 'Montserrat, sans-serif',
                                fontSmoothing: 'antialiased',
                                fontSize: '16px',
                                '::placeholder': {
                                  color: '#444444',
                                },
                              },
                              invalid: {
                                color: 'red',
                                iconColor: 'red',
                              },
                            },
                          }}
                          onChange={e => {
                            setCardElementFilled(e.complete);

                            if (!cardInteractionEventSent && (e.empty === false || e.error)) {
                              sendEvent({
                                event: AnalyticsEvent.EnterCardData,
                                params: { isGuest: true, listingId },
                              });
                              setCardInteractionEventSent(true);
                            }
                          }}
                        />
                        {!cardElementFilled && cardElementEmptyAfterSubmit && (
                          <span style={{ color: 'red' }}>Please fill in your card details</span>
                        )}
                        {error && <span style={{ color: 'red' }}>{(error as any)?.message}</span>}
                      </div>
                      {isDesktop && (
                        <>
                          <PrimaryButton
                            type="submit"
                            inProgress={isPaying}
                            onClick={() => {
                              sendEvent({
                                event: AnalyticsEvent.SubmitCheckout,
                                params: { isGuest: true, listingId },
                              });
                            }}
                          >
                            Request to buy
                          </PrimaryButton>
                          <AgreeToTnc />
                        </>
                      )}
                    </form>
                    <CheckoutSummary
                      orderData={orderData}
                      setOrderData={setOrderData}
                      onApply={handleApplyDiscountCode}
                      onRemove={handleRemoveDiscountCode}
                      discountCodeState={discountCodeState}
                      setDiscountCodeState={setDiscountCodeState}
                      isGuest={isGuest}
                      lineItemsResponse={lineItems}
                      isLoadingLineItems={isLoadingLineItems}
                    >
                      {!isDesktop && (
                        <>
                          <PrimaryButton
                            form={CHECKOUT_FORM_ID}
                            type="submit"
                            inProgress={isPaying}
                            onClick={() => {
                              sendEvent({
                                event: AnalyticsEvent.SubmitCheckout,
                                params: { isGuest: true, listingId },
                              });
                            }}
                          >
                            Request to buy
                          </PrimaryButton>
                          <AgreeToTnc />
                        </>
                      )}
                    </CheckoutSummary>
                  </div>
                </div>
              </div>
            </Page>
          );
        }}
      />
      <GuestEmailExistsModal isOpen={userExistsModalOpen} setIsOpen={setUserExistsModalOpen} />
    </>
  );
};

const AgreeToTnc = () => {
  return (
    <span className={css.agreeToTnc}>
      By clicking request to buy you agree to our{' '}
      <NamedLink name="LegalPage" params={{ tab: LegalPageTab.TermsAndConditions }}>
        Terms & Conditions
      </NamedLink>
    </span>
  );
};

export default CheckoutPageGuest;
